import React from 'react';
import Container from '~components/common/Container';
import Layout from '~components/layouts/Default';
import H1 from '~components/typography/H1';
import P from '~components/typography/P';
export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Nachricht gesendet" desc="">
      <div className="bg-gray-50 py-8">
        <Container noMargin>
          <div className="mx-auto space-y-6 text-center">
            <H1>Nachricht gesendet</H1>
            <P>Danke für deine Nachricht. Wir melden uns zeitnah bei dir.</P>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
